<template>
	<div class="label-select">
		<div class="label-select__label">{{ label }}</div>
		<v-text-field
			class="label-select__text"
			:value="value"
			:rules="rules"
			:disabled="disabled"
			:readonly="readonly"
			:append-icon="isPassword ? (show ? 'mdi-eye' : 'mdi-eye-off') : null"
			outlined
			dense
			single-line
			:class="{ 'password-input': isPassword }"
			:type="isPassword ? (show ? 'text' : type) : type"
			@click:append="isPassword ? (show = !show) : null"
			@input="onInput"
			@keydown.enter="onEnter"
			:placeholder="placeholder"
			:error="error"
			:error-messages="!errorMessages ? [] : errorMessages"
			:append-outer-icon="appendOuterIcon ? appendOuterIcon : null"
			:inputmode="inputmode"
			:enterkeyhint="enterkeyhint"
		></v-text-field>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyLabelTextField',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: String | Number,
		},
		rules: {
			required: false,
			type: Array,
			default: () => [],
		},
		type: {
			required: false,
			type: String,
			default: 'text',
		},
		disabled: {
			required: false,
			type: Boolean,
		},
		readonly: {
			required: false,
			type: Boolean,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
		placeholder: {
			required: false,
			type: String,
		},
		error: {
			required: false,
			type: Boolean,
			default: false,
		},
		errorMessages: {
			required: false,
		},
		appendOuterIcon: {
			required: false,
		},
		inputmode: {
			required: false,
		},
		enterkeyhint: {
			required: false,
			type: String,
			default: 'none',
		},
		search: {
			required: false,
			type: Boolean,
		},
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	data() {
		return {
			show: false,
		}
	},
	computed: {
		validate() {
			return this.rules.length > 0
		},
		isPassword() {
			return this.type === 'password'
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onInput(val) {
			this.$emit('updateValue', val)
			this.$emit('onInput', val)
		},
		onEnter() {
			const { value } = this
			this.$emit('updateValue', value)
			this.$emit('onEnter', value)
		},
	},
}
</script>
<style lang="scss" scoped>
.label-select {
	display: flex;
	align-items: center;
	min-width: calc(180px + 5rem);
	border-radius: 8px;
	border: solid 2px $lineColor;
	height: 48px;
	position: relative;
	@include rwd(776) {
		width: 80vw;
	}
	&__label {
		font-size: 1rem;
		border-right: solid $lineColor;
		color: $lightTextColor;
		white-space: nowrap;
		max-width: 35%;
		padding: 0 12px;
		text-align: center;
	}
	&__text {
		border: none !important;
		flex: 1;
		max-width: none !important;
		min-width: 65%;

		&::v-deep {
			.v-select__selection {
				font-size: 1rem;
				margin: 0;
				line-height: 1.2rem;
			}
		}
	}
}
</style>
