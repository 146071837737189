<template>
	<MyFormRow
		:label="label"
		:width="labelWidth"
		:labelAlign="labelAlign"
		:color="color"
	>
		<div class="text" :style="{ marginBottom: textMarginBottom }">
			<slot></slot>
		</div>
	</MyFormRow>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyRowText',
	// components: { COMPONENT_NAME },
	props: {
		marginBottom: {
			required: false,
			type: Number | String,
			default: 12,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
	},
	// data() {return {}},
	computed: {
		textMarginBottom() {
			const { marginBottom } = this
			return typeof marginBottom === 'number'
				? marginBottom + 'px'
				: marginBottom
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="scss" scoped>
.text {
	font-size: 1rem;
	padding-top: 8px;
	text-align: left;
}
</style>
