<template>
	<div class="forget-password">
		<div class="login__title">
			<v-btn
				small
				class="login__title__icon"
				fab
				text
				@click="closeForgerPassword"
			>
				<v-icon size="40">mdi-chevron-left</v-icon>
			</v-btn>
			<div class="login__second">忘記密碼</div>
		</div>

		<v-stepper class="stepper" v-model="step" color="spaicel" light>
			<v-stepper-items>
				<v-stepper-content step="1">
					<v-form v-model="emailValid">
						<div class="form-body">
							<div class="card_input">
								<v-text-field
									placeholder="請輸入原註冊信箱"
									v-model="emailData.email"
									:rules="emailRules.email"
									outlined
									dense
									single-line
									clearable
									@keydown.enter="$refs.accountRef.focus"
									><div style="white-space: nowrap" slot="prepend">
										電子信箱 <span class="require">*</span>
									</div></v-text-field
								>
							</div>
							<div class="card_input">
								<v-text-field
									ref="accountRef"
									placeholder="請輸入帳號"
									v-model="emailData.account"
									:rules="emailRules.account"
									outlined
									dense
									single-line
									clearable
									@keydown.enter="onEmailNext"
									><div style="white-space: nowrap" slot="prepend">
										帳號 <span class="require">*</span>
									</div></v-text-field
								>
							</div>
						</div>

						<v-btn
							class="submit-btn"
							:disabled="!emailValid"
							color="com2Color"
							@click="onEmailNext"
							:loading="subLoading"
							:dark="emailValid"
							>發送驗證碼</v-btn
						>
					</v-form>
				</v-stepper-content>

				<v-stepper-content step="2">
					<v-form v-model="codeValid">
						<div class="form-body">
							<div class="card_input">
								<v-text-field
									class="verify"
									placeholder="至信箱確認驗證碼"
									v-model="codeData"
									:rules="codeRules"
									outlined
									dense
									single-line
									clearable
									type="number"
									@input="handleInputCodeData"
									@keydown.enter="verifyCode"
								>
									<template v-slot:prepend>
										<div style="white-space: nowrap">
											驗證碼 <span class="require">*</span>
										</div>
									</template>

									<template v-slot:append>
										<v-fade-transition leave-absolute>
											<div class="vertical-center">
												<v-progress-circular
													v-if="resendLoading"
													size="24"
													color="info"
													indeterminate
												></v-progress-circular>

												<div
													v-if="!resendLoading && staySecond === 0"
													@click="sendVerifyCode"
													style="
														margin-top: 4px;
														color: #135cd1;
														white-space: nowrap;
													"
												>
													重發
												</div>
												<div
													style="
														margin-top: 4px;
														color: #7f8283;
														white-space: nowrap;
													"
													v-else-if="!resendLoading && staySecond > 0"
												>
													{{ staySecond }}
												</div>
											</div>
										</v-fade-transition>
									</template>
								</v-text-field>
							</div>
						</div>

						<v-btn
							class="submit-btn"
							:disabled="!codeValid || codeData?.length !== 6"
							color="com2Color"
							@click="verifyCode"
							:dark="codeValid && codeData?.length === 6"
							:loading="subLoading"
							>驗證</v-btn
						>
					</v-form>
				</v-stepper-content>

				<v-stepper-content step="3">
					<v-form v-model="newPasswordValid">
						<div class="form-body form-code">
							<div class="card_input">
								<v-text-field
									placeholder="限英文大小寫或數字，總長度8碼(含)以上"
									v-model="newPasswordData.password"
									:rules="newPasswordRules.password"
									outlined
									dense
									single-line
									clearable
									type="password"
									@input="changeNewPassword"
									@keydown.enter="$refs.replyRef.focus"
									><div style="white-space: nowrap" slot="prepend">
										新密碼 <span class="require">*</span>
									</div></v-text-field
								>
							</div>

							<PasswordStrength
								:password="newPasswordData.password"
								v-model="scoreStatus"
							></PasswordStrength>

							<v-text-field
								ref="replyRef"
								placeholder="請輸入相同密碼"
								v-model="newPasswordData.reply"
								:rules="newPasswordRules.reply"
								outlined
								dense
								single-line
								clearable
								type="password"
								@keydown.enter="onSubmit"
								><div style="white-space: nowrap" slot="prepend">
									確認密碼 <span class="require">*</span>
								</div></v-text-field
							>
						</div>

						<v-btn
							class="submit-btn"
							:disabled="!newPasswordValid"
							color="com2Color"
							:dark="newPasswordValid"
							@click="onSubmit"
							:loading="subLoading"
							>送出</v-btn
						>
					</v-form>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</div>
</template>

<script>
import PasswordStrength from '@/components/PasswordStrength'
export default {
	name: 'ForgetPassword',
	components: {
		PasswordStrength,
	},
	data() {
		return {
			step: '1',
			subLoading: false,
			emailRule:
				/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
			emailValid: false,
			emailData: {
				email: '',
				account: '',
			},
			emailRules: {
				email: [
					v => !!v || '信箱為必填',
					v => this.emailRule.test(v) || '信箱格式不正確',
				],
				account: [v => !!v || '店家帳號為必填'],
			},

			resendLoading: false,
			codeValid: false,
			codeData: '',
			codeRules: [v => !!v || '請輸入驗證碼'],
			staySecond: 0,

			newPasswordValid: false,
			newPasswordData: {
				password: '',
				reply: '',
			},
			newPasswordRules: {
				password: [
					v => !!v || '新密碼為必填',
					() => this.scoreStatus || '新密碼強度須為中(含)以上',
				],
				reply: [
					v => !!v || '確認密碼為必填',
					v => v === this.newPasswordData.password || '新密碼不一致',
				],
			},
			scoreStatus: '',
		}
	},
	watch: {
		staySecond() {
			if (this.staySecond > 0) {
				setTimeout(() => {
					this.staySecond--
				}, 1000)
			}
		},
	},
	mounted() {},
	methods: {
		changeNewPassword() {
			this.newPasswordData.reply = ''
		},
		closeForgerPassword() {
			this.init()
			this.$emit('closeForgerPassword')
		},
		init() {
			this.initEmailData()
			this.initCodeData()
			this.initNewPasswordData()
		},
		initEmailData() {
			this.emailData = {
				email: '',
				account: '',
			}
		},
		initCodeData() {
			this.codeData = ''
		},
		initNewPasswordData() {
			this.newPasswordData = {
				password: '',
				reply: '',
			}
		},

		onEmailNext() {
			if (!this.emailValid) return
			this.subLoading = true

			this.$http
				.sendVerifyCode(this.emailData)
				.then(res => {
					this.step = '2'
					this.staySecond = res.data
					this.subLoading = false
				})
				.catch(err => {
					this.$store.commit('showMessage', {
						text: err,
						color: 'error',
					})
				})
				.finally(() => (this.subLoading = false))
		},
		sendVerifyCode() {
			this.resendLoading = true
			this.$http
				.sendVerifyCode(this.emailData)
				.then(res => {
					this.staySecond = res.data
					this.$store.commit('showMessage', `驗證碼發送成功!`)
				})
				.catch(err => {
					this.$store.commit('showMessage', {
						text: err,
						color: 'error',
					})
				})
				.finally(() => {
					this.resendLoading = false
				})
		},
		handleInputCodeData(value) {
			this.$nextTick(() => {
				this.codeData = value.slice(0, 6)
			})
		},
		verifyCode() {
			if (!this.codeValid) return
			this.subLoading = true
			this.$http
				.verifyCode({
					account: this.emailData.account,
					code: this.codeData.trimEnd(),
				})
				.then(res => {
					if (res.status) {
						this.step = '3'
					} else {
						this.$store.commit('showMessage', {
							text: res.message,
							color: 'error',
						})
					}
				})
				.catch(err => {
					this.$store.commit('showMessage', {
						text: err,
						color: 'error',
					})
				})
				.finally(() => {
					this.subLoading = false
				})
		},
		onSubmit() {
			if (!this.codeValid) return
			this.subLoading = true
			const loginData = {
				account: this.emailData.account,
				password: this.newPasswordData.password,
			}
			this.$http
				.changePassword(loginData)
				.then(res => {
					this.$store.commit('showMessage', `密碼修改成功!`)
					this.$emit('login', loginData)
					this.$emit('closeForgerPassword')
				})
				.catch(err => {
					this.$store.commit('showMessage', {
						text: err,
						color: 'error',
					})
				})
				.finally(() => {
					this.subLoading = false
				})
		},
	},
}
</script>

<style scoped lang="scss">
.login__title {
	display: flex;
	font-weight: bold;
	font-family: 微軟正黑體;
	align-items: center;
	font-size: 1.2rem;
}
.login__title {
	.login__second {
		width: 100%;
		text-align: center;
		padding-right: 40px;
	}
}
.login {
	.stepper {
		box-shadow: none;
		&::v-deep {
			.v-stepper__content {
				padding: 0;
			}
			.v-stepper__header {
				box-shadow: none;
			}
		}
	}
	.submit-btn {
		margin-top: 16px;
		width: 100%;
		height: 48px;
	}
	.form-body {
		margin-top: 30px;
		.card_input {
			margin-bottom: 12px;

			.verify {
				/* 隱藏上下箭頭按鈕 */
				::v-deep {
					input::-webkit-inner-spin-button,
					input::-webkit-outer-spin-button {
						-webkit-appearance: none !important;
						-moz-appearance: textfield;
					}

					.v-input__icon--clear {
						margin-right: 20px;
					}
					.v-input__append-inner {
						height: 100%;
					}
				}
			}
		}
	}
}
</style>
