import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import zhHant from 'vuetify/lib/locale/zh-Hant'

Vue.use(Vuetify)

export default new Vuetify({
	lang: {
		locales: { zhHant },
		current: 'zhHant',
	},
	theme: {
		themes: {
			light: {
				primary: '#000',
				secondary: '#b0bec5',
				accent: '#8c9eff',
				error: '#D52828',
				com1Color: '#EDB477', // - 按鈕色、區塊背景色
				com2Color: '#294B71', // 按鈕色、文字色、區塊背景色
				tableColor: '#92BBDD', // 內用桌號&預約區塊背景底色
				orderCardColor: '#2CCB8A', // 訂單區塊背景色、文字色
				activeColor: '#D52828', // 區塊背景色、選中按鈕顏色、提醒色
				discountColor: '#F37201', // 折扣文字色
				disableColor: '#F6F6F6', // 區塊背景色、按鈕未選中色、白色背景按鈕禁用色
				lineColor: '#EEEEEE', // 分隔線、按鈕外框線
				grayColor: '#E1E1E1', // 灰色區塊分隔線
				selectColor: '#707070', // 勾選、圈選外框線
				lightTextColor: '#000000', // 淺色背景文字色
				darkTextColor: '#FFFFFF', // 深色背景文字色
				disable2Color: '#EBEBEB', // 背景色、白色背景按鈕禁用色
				headerColor: '#383838', // header背景色
				switchOffColor: '#FE4B4B', // Switch_off、藍芽連接失敗
				switchOnColor: '#27CE8A', // Switch_on、藍芽連接成功
				menuClass1: '#B576A0', // 餐點分類色
				menuClass2: '#DCAA74', // 餐點分類色
				menuClass3: '#66ACAF', // 餐點分類色
				menuClass4: '#6A70C1', // 餐點分類色
				menuClass5: '#E1826B', // 餐點分類色
			},
			dark: {
				primary: '#000',
				secondary: '#b0bec5',
				accent: '#8c9eff',
				error: '#D52828',
				com1Color: '#EDB477', // - 按鈕色、區塊背景色
				com2Color: '#294B71', // 按鈕色、文字色、區塊背景色
				tableColor: '#92BBDD', // 內用桌號&預約區塊背景底色
				orderCardColor: '#2CCB8A', // 訂單區塊背景色、文字色
				activeColor: '#D52828', // 區塊背景色、選中按鈕顏色、提醒色
				discountColor: '#F37201', // 折扣文字色
				disableColor: '#F6F6F6', // 區塊背景色、按鈕未選中色、白色背景按鈕禁用色
				lineColor: '#EEEEEE', // 分隔線、按鈕外框線
				grayColor: '#E1E1E1', // 灰色區塊分隔線
				selectColor: '#707070', // 勾選、圈選外框線
				lightTextColor: '#000000', // 淺色背景文字色
				darkTextColor: '#FFFFFF', // 深色背景文字色
				disable2Color: '#EBEBEB', // 背景色、白色背景按鈕禁用色
				headerColor: '#383838', // header背景色
				switchOffColor: '#FE4B4B', // Switch_off、藍芽連接失敗
				switchOnColor: '#27CE8A', // Switch_on、藍芽連接成功
				menuClass1: '#B576A0', // 餐點分類色
				menuClass2: '#DCAA74', // 餐點分類色
				menuClass3: '#66ACAF', // 餐點分類色
				menuClass4: '#6A70C1', // 餐點分類色
				menuClass5: '#E1826B', // 餐點分類色
			},
		},
	},
})
