export default {
	namespaced: true,
	// modules: {}
	state: {
		updateStore: [],
	},
	mutations: {
		setUpdateStore($, val) {
			$.updateStore = val
		},
	},
	actions: {},
}
