import store from '../store'

const checkMobile = () => {
	if (/iPad|iPhone|iPod/.test(navigator.platform)) {
		return true
	} else if (
		navigator.maxTouchPoints &&
		navigator.maxTouchPoints > 2 &&
		/MacIntel/.test(navigator.platform)
	) {
		return true
	}

	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent,
	)
}

export default Vue => {
	Vue.prototype.$checkMobile = checkMobile
}
