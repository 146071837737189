<template>
	<div class="search-row">
		<div class="search-row__left">
			<slot></slot>
		</div>
		<div class="search" @click="search">
			<img class="switch-icon" src="../assets/common_btn_search.png" alt="" />
		</div>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MySearchRow',
	// components: { COMPONENT_NAME },
	// props: {},
	// data() {return {}},
	// computed: {},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		search() {
			this.$emit('search')
		},
	},
}
</script>
<style lang="scss" scoped>
.search-row {
	display: flex;
	align-items: center;
	align-content: flex-start;
	border-radius: 8px;
	height: 48px;
	overflow: hidden;
	min-width: 268px;
	//@include rwd(425) {
	//	flex-direction: column;
	//	justify-content: flex-start;
	//	align-items: flex-start;
	//}
	.search-row__left {
		flex: 1;
	}
	&::v-deep {
		.v-input {
			&.v-text-field--outlined {
				border-radius: 8px 0 0 8px !important;
			}
		}
	}

	.search {
		width: 48px;
		height: 48px;
		min-width: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $com2Color;
		img {
			width: 24px;
			height: 24px;
		}
	}
}
</style>
