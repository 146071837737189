export const formatDate = (date = new Date(), type = 'yyyy-MM-dd') => {
	const days = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	]
	const abridgeDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]
	const abridgeMonths = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	]
	const increaseStartZero = number =>
		typeof number === 'number'
			? number < 10
				? `0${number}`
				: number
			: number.length < 2
			? `0${number}`
			: number
	const yyyy = 'yyyy' // 2020
	const yy = 'yy' // 2002 -> 02, 2020 -> 20
	const y = 'y' // 2002 -> 2, 2020 -> 20
	const MMMM = 'MMMM' // January
	const MMM = 'MMM' // Jan
	const MM = 'MM' // 12 -> 12, 6 -> 06
	const M = 'M' // 12 -> 12, 6 -> 6
	const dddd = 'dddd' // Sunday
	const ddd = 'ddd' // Sun
	const dd = 'dd' // 7 -> 07
	const d = 'd' // 7 -> 7
	const hh = 'hh' // 20 -> 08, 8 -> 08
	const h = 'h' // 20 -> 8, 8 -> 8
	const HH = 'HH' // 20 -> 20, 8 -> 08
	const H = 'H' // 20 -> 20, 8 -> 8
	const mm = 'mm' // 20 -> 20, 8 -> 08
	const m = 'm' // 20 -> 20, 8 -> 8
	const ss = 'ss' // 20 -> 20, 8 -> 08
	const s = 's' // 20 -> 20, 8 -> 8
	const tt = 'tt' // 20 -> PM, 8 -> AM
	const t = 't' // 20 -> P, 8 -> A
	const year = date.getFullYear()
	const strYear = String(year)
	const lastStrYear = strYear.substr(-2)
	const month = date.getMonth()
	const realMonth = month + 1
	const day = date.getDay()
	const getDate = date.getDate()
	const hour = date.getHours()
	const minusHour = hour - 12
	const computedHour = minusHour > 0 ? minusHour : hour
	const minute = date.getMinutes()
	const second = date.getSeconds()
	const tmCondition = hour > 12
	const ttm = tmCondition ? 'PM' : 'AM'
	const tm = tmCondition ? 'P' : 'A'
	let result = type
	if (result.indexOf(yyyy) !== -1) {
		result = result.replace(yyyy, year)
	} else if (result.indexOf(yy) !== -1) {
		result = result.replace(yy, lastStrYear)
	} else if (result.indexOf(y) !== -1) {
		result = result.replace(
			y,
			Number(lastStrYear) > 9 ? lastStrYear : strYear.substr(-1),
		)
	}
	if (result.indexOf(MMMM) !== -1) {
		result = result.replace(MMMM, months[month])
	} else if (result.indexOf(MMM) !== -1) {
		result = result.replace(MMM, abridgeMonths[month])
	} else if (result.indexOf(MM) !== -1) {
		result = result.replace(MM, increaseStartZero(realMonth))
	} else if (result.indexOf(M) !== -1) {
		result = result.replace(M, realMonth)
	}
	if (result.indexOf(dddd) !== -1) {
		result = result.replace(dddd, days[day])
	} else if (result.indexOf(ddd) !== -1) {
		result = result.replace(ddd, abridgeDays[day])
	} else if (result.indexOf(dd) !== -1) {
		result = result.replace(dd, increaseStartZero(getDate))
	} else if (result.indexOf(d) !== -1) {
		result = result.replace(d, getDate)
	}
	if (result.indexOf(HH) !== -1) {
		result = result.replace(HH, increaseStartZero(hour))
	} else if (result.indexOf(H) !== -1) {
		result = result.replace(H, hour)
	} else if (result.indexOf(hh) !== -1) {
		result = result.replace(hh, increaseStartZero(computedHour))
	} else if (result.indexOf(h) !== -1) {
		result = result.replace(h, computedHour)
	}
	if (result.indexOf(mm) !== -1) {
		result = result.replace(mm, increaseStartZero(minute))
	} else if (result.indexOf(m) !== -1) {
		result = result.replace(m, minute)
	}
	if (result.indexOf(ss) !== -1) {
		result = result.replace(ss, increaseStartZero(second))
	} else if (result.indexOf(s) !== -1) {
		result = result.replace(s, second)
	}
	if (result.indexOf(tt) !== -1) {
		result = result.replace(tt, ttm)
	} else if (result.indexOf(t) !== -1) {
		result = result.replace(t, tm)
	}
	return result
}

const getToday = () => {
	const Today = new Date()
	return formatDate(Today)
}

const getMonth = () => {
	const Today = new Date()
	const month =
		Today.getMonth() + 1 >= 10
			? Today.getMonth() + 1
			: '0' + (Today.getMonth() + 1)

	const lastDay = new Date(Today.getFullYear(), Today.getMonth() + 1, 0)
	const monthLastDay =
		lastDay.getMonth() + 1 >= 10
			? lastDay.getMonth() + 1
			: '0' + (lastDay.getMonth() + 1)
	const d =
		lastDay.getDate() >= 10 ? lastDay.getDate() : '0' + lastDay.getDate()

	return [
		Today.getFullYear() + '-' + month + '-01',
		Today.getFullYear() + '-' + monthLastDay + '-' + d,
	]
}

const getLastMonth = () => {
	const Today = new Date()
	Today.setMonth(Today.getMonth() - 1)
	const month =
		Today.getMonth() + 1 >= 10
			? Today.getMonth() + 1
			: '0' + (Today.getMonth() + 1)

	const lastDay = new Date(Today.getFullYear(), Today.getMonth() + 1, 0)
	const monthLastDay =
		lastDay.getMonth() + 1 >= 10
			? lastDay.getMonth() + 1
			: '0' + (lastDay.getMonth() + 1)
	const d =
		lastDay.getDate() >= 10 ? lastDay.getDate() : '0' + lastDay.getDate()

	return [
		Today.getFullYear() + '-' + month + '-01',
		Today.getFullYear() + '-' + monthLastDay + '-' + d,
	]
}

const getWeek = () => {
	const now = new Date()
	const nowTime = now.getTime()
	const day = now.getDay()
	let oneDayTime, MondayTime, SundayTime
	if (day === 0) {
		oneDayTime = 24 * 60 * 60 * 1000
		MondayTime = new Date(nowTime - 6 * oneDayTime) //显示周一
		SundayTime = new Date(nowTime + 0 * oneDayTime) //显示周日
	} else {
		oneDayTime = 24 * 60 * 60 * 1000
		MondayTime = new Date(nowTime - (day - 1) * oneDayTime) //显示周一
		SundayTime = new Date(nowTime + (7 - day) * oneDayTime) //显示周日
	}

	return [formatDate(MondayTime), formatDate(SundayTime)]
}

const getLastWeek = () => {
	const now = new Date(getWeek()[0])
	now.setDate(now.getDate() - 1)
	const nowTime = now.getTime()
	const day = now.getDay()
	let oneDayTime, MondayTime, SundayTime
	if (day === 0) {
		oneDayTime = 24 * 60 * 60 * 1000
		MondayTime = new Date(nowTime - 6 * oneDayTime) //显示周一
		SundayTime = new Date(nowTime + 0 * oneDayTime) //显示周日
	} else {
		oneDayTime = 24 * 60 * 60 * 1000
		MondayTime = new Date(nowTime - (day - 1) * oneDayTime) //显示周一
		SundayTime = new Date(nowTime + (7 - day) * oneDayTime) //显示周日
	}

	return [formatDate(MondayTime), formatDate(SundayTime)]
}

const dayNamesZh = ['(日)', '(一)', '(二)', '(三)', '(四)', '(五)', '(六)']

export default function (Vue) {
	Vue.prototype.$formatDate = formatDate
	Vue.prototype.$date = {
		getToday,
		getMonth,
		getWeek,
		getLastWeek,
		getLastMonth,
		dayNamesZh,
	}
}
