import store from '../store'

const getStoreId = () => {
	const storageStoreId = store.state.storage.storeId
	return storageStoreId === null ? undefined : { storeId: storageStoreId }
}

const getMobile = () => {
	const mobile = store.state.storage.mobile
	return mobile
}

export const isWebview = () => {
	return !!window.ReactNativeWebView?.postMessage
}

export default Vue => {
	;(Vue.prototype.$getStoreId = getStoreId),
		(Vue.prototype.$getMobile = getMobile)
	Vue.prototype.$isReactNative = isWebview
}
