import condition from './condition'
import http from './service/api'
import date from './date'
import checkLazy from './checkLazy'
import permission from './permission'
import downloadFile from './downloadFile'
import storeId from './storeId'
import export2excel from './excel/export2excel'
import tableFooter from '@/utils/tableFooter'

export default Vue => {
	condition(Vue)
	http(Vue)
	date(Vue)
	checkLazy(Vue)
	permission(Vue)
	downloadFile(Vue)
	storeId(Vue)
	export2excel(Vue)
	tableFooter(Vue)
}
