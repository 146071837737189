import service from './service'

export default {
	getList: params => service.post(`back/store/getPage`, params),

	// 取得所有店家
	getAssociatedStoreList: params =>
		service.get(`back/store/getAssociatedStoreList`, { params }),

	add: params =>
		service.post(`back/store/add`, params, {
			headers: { 'Content-Type': 'multipart/form-data' },
		}),

	del: params => service.delete(`back/store/remove`, { params }),
	update: params =>
		service.put(`back/store/update`, params, {
			headers: { 'Content-Type': 'multipart/form-data' },
		}),

	getDetail: params => service.get(`back/store/getDetail`, { params }),

	randomKey: params => service.get(`back/store/randomBindCode`),

	// 取得搜尋所有店家(Excel)
	getAllStoresWithExcel: params => service.post(`back/store/getList`, params),

	// 取得店家合約狀態
	getStoreContractStatus: params =>
		service.get(`back/contract/status`, { params }),

	// 修改店家時間
	updateStoreBusinessAndRestTime: params =>
		service.put('back/account/updateStoreBusinessAndRestTime', params),

	// 修改店家開關
	updateStoreSetting: params =>
		service.put('back/account/updateStoreSetting', params),

	// 店家設定
	getStoreSetting: storeId =>
		service.get(`/back/account/getStoreSetting?storeId=${storeId}`),

	// 電子發票設定
	getStoreInvoiceSetting: storeId =>
		service.get(`/back/account/getStoreInvoiceSetting?storeId=${storeId}`),

	// 驗證帳號是否可用
	getAvailable: params => service.get(`back/store/isAvailable`, { params }),

	// 修改手機點餐設定
	updateStoreMobileOrderSetting: formdata =>
		service.put(`/back/account/updateStoreMobileOrderSetting`, formdata),

	// 修改電子發票設定
	updateStoreInvoiceSetting: data =>
		service.put(`/back/account/updateStoreInvoiceSetting`, data),

	// 驗證管理者識別碼
	validManagerCode: params =>
		service.post(`/back/account/validManagerCode`, params),

	// 取得UberEats店家授權
  getUberEatsStoreAuthorization: data =>
		service.post(`back/store/getUberEatsStoreAuthorization`, data),

	// 取得UberEats授權店家列表
  getUberEatsAuthorizationStores: storeId =>
		service.get(`back/store/getUberEatsAuthorizationStores?id=${storeId}`),

	// UberEats店家資訊
	getUberEatsStoreInfo: storeId =>
		service.get(`back/store/uberEatsStoreInfo?storeId=${storeId}`),

  // UberEats應用綁定店家
  bindUberEatsStore: data =>
    service.put(`back/store/bindUberEatsStore`, data),

  // UberEats應用解綁店家
  unbindUberEatsStore: data =>
    service.put(`back/store/unbindUberEatsStore`, data),

  // 修改Uber 電子發票開關
  updateUberInvoiceSwitch: data =>
    service.put(`back/store/uberInvoiceSwitch`, data),

  // 複製菜單
	copyMenu: data => service.post(`back/store/copyMenu`, data),
}
