<template>
	<div class="file">
		<div class="file-area">
			<button class="file-btn">
				<span class="file-btnText">{{
					fileName
						? `已上傳 ${fileName}`
						: '點擊上傳圖檔，格式PNG、JPG、JPEG、BMP，限1張'
				}}</span>
				<input
					class="file-upload"
					type="file"
					accept="image/png, image/jpg, image/jpeg, image/bmp"
					@change="previewImg($event)"
				/>
			</button>
			<div class="file-remind">建議圖片尺寸375*150px，檔案大小1MB內(含)</div>
		</div>
		<div class="file-display" v-if="value">
			<img id="output" @error="onSetNoPicDefault" />
			<img
				class="file-cancel"
				src="../assets/close.png"
				alt=""
				@click="cancelImg"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MyImgFile',
	props: {
		value: {
			type: String | File | Array,
			default: null,
		},
	},
	model: {
		prop: 'value',
		event: 'updateImgFile',
	},
	data() {
		return {
			fileName: '',
			files: {},
			isFirstIn: true,
			isDelFiles: null,
		}
	},
	// computed: {},
	watch: {
		fileName(name) {
			if (name) return name
		},
		value(val) {
			if (this.isFirstIn) {
				this.files = val
				this.isFirstIn = null
			}
		},
		files(val) {
			if (val && !this.files.length && !this.isDelFiles) {
				this.files = val
			} else if (val && this.files.length) {
				this.$nextTick(() => (document.getElementById('output').src = val))
			} else if (!this.isDelFiles) {
				this.files = null
			}
			this.$emit('updateImgFile', this.files)
			this.isDelFiles = null
		},
	},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onSetNoPicDefault(event) {
			this.$emit('updateImgFile', null)
		},
		previewImg(event) {
			let input = event.target

			//檢查檔案格式
			if (!this.checkFile(input.files[0])) {
				return
			}

			//檢查檔案大小
			if (input.files[0].size > 1048576) {
				if (!this.files) {
					this.files = ''
					this.fileName = ''
				}
				this.$store.commit('showMessage', {
					text: '新增失敗！檔案大小超過1MB',
					color: 'error',
				})
				return
			}

			let reader = new FileReader()
			reader.onload = function () {
				let dataURL = reader.result
				let output = document.getElementById('output')
				output.src = dataURL
			}
			this.fileName = event.target.files[0].name
			reader.readAsDataURL(input.files[0])
			this.files = input.files[0]
			this.$emit('updateImgFile', this.files)
			event.target.value = ''
		},
		cancelImg() {
			let output = document.getElementById('output')
			output.src = ''
			this.fileName = ''
			this.files = null
			this.isDelFiles = true
			this.$emit('updateImgFile', this.files)
		},
		checkFile(val) {
			let validExts = new Array('.png', '.jpg', '.jpeg', '.bmp')
			let fileExt = val.name
			fileExt = fileExt.substring(fileExt.lastIndexOf('.'))
			if (validExts.indexOf(fileExt) < 0) {
				this.$store.commit('showMessage', {
					text: `新增失敗！檔案類型錯誤`,
					color: 'error',
				})
				return false
			} else {
				return true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.file-area {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
}

.file-btn {
	position: relative;
	padding: 5px;
	border-radius: 3px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	transition: all linear 0.1s;

	&:hover {
		border: 1px solid rgba(0, 0, 0, 0.87);
		background-color: #fff;
		border-radius: 3px;
	}
}

.file-remind {
	position: relative;
	font-size: 1rem;
	color: $lightTextColor;
	padding-left: 16px;
}

.file-btnText {
	flex: 1;
	font-size: 0.75rem;
	color: #999;
	display: inline-block;
	padding: 5px;
	text-align: left;
}

.file-upload {
	position: absolute;
	appearance: none;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	cursor: default;
	opacity: 0;
}

.file-display {
	position: relative;
	margin-top: 15px;
	display: flex;
	width: 150px;
	height: 150px;
	margin-bottom: 12px;
}

#output {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	object-fit: cover;
}

.file-cancel {
	position: absolute;
	top: -14px;
	right: -14px;
	height: 28px;
	width: 28px;
	cursor: pointer;
}
</style>
