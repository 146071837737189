<template>
  <v-app id="app">
    <audio v-if="!isReactNative" id="new-order-audio" muted="muted">
      <source src="./assets/new_order_notice.mp3" type="audio/mp3"/>
    </audio>
    <audio id="booking-order-notice" muted="muted">
      <source src="./assets/booking_order_notice.mp3" type="audio/mp3"/>
    </audio>
    <audio id="idle-order-notice" muted="muted">
      <source src="./assets/idle_order_notice.mp3" type="audio/mp3"/>
    </audio>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
    <v-snackbar
        v-model="isShow"
        :color="
				$store.state.messageColor === 'error'
					? 'rgba(213,40,40,0.9)'
					: 'rgba(0,0,0,0.8)'
			"
        :timeout="2000"
        centered
        class="toast"
    >
      <img
          class="error-img"
          v-if="$store.state.messageColor === 'error'"
          src="./assets/toast_img_notice.png"
      />
      <div class="toast-text">
        {{ messageText }}
      </div>
    </v-snackbar>
    <div class="fixed-version">{{ version }}</div>
    <MyAnnouncementDisplayModal/>
    <MyMaintenanceModal/>
    <v-overlay :value="loading">
      <svga src="common_img_loading.svga" style="width: 300px; height: 300px"/>
      <div class="load-text">
        <div class="load-text1">頁</div>
        <div class="load-text2">面</div>
        <div class="load-text3">加</div>
        <div class="load-text4">載</div>
        <div class="load-text5">中</div>
        <div class="load-text6">.</div>
        <div class="load-text7">.</div>
        <div class="load-text8">.</div>
      </div>
    </v-overlay>
  </v-app>
</template>

<script>
import MyAnnouncementDisplayModal from '@/components/AnnouncementDisplayModal.vue'
import {svga} from 'vue-svga'
import MyMaintenanceModal from '@/components/MaintenanceModal.vue'

export default {
  name: 'App',
  components: {MyMaintenanceModal, svga, MyAnnouncementDisplayModal},
  data() {
    return {
      version: '',
      height: 0,
      showPosSwitch: false,
    }
  },
  computed: {
    messageText() {
      return this.$store.state.messageText
    },
    messageColor() {
      switch (this.$store.state.messageColor) {
        case 'error':
          return 'rgba(213,40,40,0.9)'
          break
        default:
          return 'rgba(0,0,0,0.8)'
      }
    },
    path() {
      return this.$route.path === '/store-pos'
    },
    loading() {
      return this.$store.state.loading
    },
    isShow: {
      get() {
        return this.$store.state.isMessage
      },
      set(isShow) {
        if (!isShow) {
          this.$store.commit('hideMessage')
        }
      },
    },
    storeId() {
      return this.$store.state.storage.storeId
    },
    isMac() {
      return /macintosh|mac os x/i.test(navigator.userAgent)
    },
    isReactNative() {
      return this.$isReactNative()
    },
    BTPosPrinterDevice() {
      return this.$store.state.order.BTPosPrinterDevice
    },
    BTPosPrinterDeviceSecond() {
      return this.$store.state.order.BTPosPrinterDeviceSecond
    },
    scaleFont() {
      return this.$store.state.storage.scaleFont || 1
    },
    manager() {
      return this.$store.state.storage.manager
    },
  },
  watch: {
    storeId() {
      this.getStoreSetting()
      this.setVhHeightStyle()
      if (!this.manager && this.storeId) {
        this.getAnnouncementDisplayList()
      }
			// 生成storeId代表登入，登入後才開始檢查綁定
			this.checkBinding()
    },
    BTPosPrinterDevice() {
      const BTPosPrinterDevice = this.BTPosPrinterDevice
      if (BTPosPrinterDevice) {
        BTPosPrinterDevice.addEventListener(
            'gattserverdisconnected',
            this.onGattServerDisconnected,
        )
      } else {
        // BTPosPrinter.removeEventListener('characteristicvaluechanged', this.onCharacteristicValueChanged );
      }
    },
    BTPosPrinterDeviceSecond() {
      const BTPosPrinterDeviceSecond = this.BTPosPrinterDeviceSecond
      if (BTPosPrinterDeviceSecond) {
        BTPosPrinterDeviceSecond.addEventListener(
            'gattserverdisconnectedSecond',
            this.onGattServerDisconnectedSecond,
        )
      } else {
        // BTPosPrinter.removeEventListener('characteristicvaluechanged', this.onCharacteristicValueChanged );
      }
    },
    scaleFont() {
      this.changeFontSize()
    },
    '$route.path'() {
      this.setVhHeightStyle()
    },
    '$store.state.refreshSetting'() {
      if (this.$store.state.refreshSetting) {
        if (this.storeId) {
          this.$http.store.getStoreSetting(this.storeId).then(res => {
            this.saveAndChangeStoreSetting(res)
          })
        }
        this.$store.commit('setRefreshSetting', false)
      }
    },
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    const reload = urlParams.get('reload')
    if (reload) {
      this.$store.commit('showMessage', '已成功清存緩存')
    }

    const iosVersion = urlParams.get('iosVersion')
    const androidVersion = urlParams.get('androidVersion')

    if (iosVersion) {
      this.$store.commit('storage/setIosVersion', iosVersion)
    }
    if (androidVersion) {
      this.$store.commit('storage/setAndroidVersion', androidVersion)
    }
    if (iosVersion || androidVersion || reload) {
      this.$router.history.replace(window.location.pathname)
    }

    this.setVhHeightStyle()
    window.addEventListener('resize', this.setVhHeightStyle)

    if (!this.isReactNative) {
      const onAudioPlay = () => {
        vm.$store.commit('playNoticeAudio')
      }

      const audio = document.getElementById('new-order-audio')

      const onAutoPlayAudio = () => {
        audio.autoplay = true

        document.body.removeEventListener('click', onAutoPlayAudio, false)
        document.body.removeEventListener('touchstart', onAutoPlayAudio, false)
      }

      audio.addEventListener('ended', onAudioPlay)
      document.body.addEventListener('click', onAutoPlayAudio, false)
      document.body.addEventListener('touchstart', onAutoPlayAudio, false)
    }
  },
  async created() {
   // if (this.isReactNative) {
      const root = /iPhone|iPad|iPod|Mac/i.test(navigator.userAgent)
          ? window
          : document
      root.addEventListener('message', this.reactNativeMessage)
   // }
    await this.$http.getCurrent().then(res => {
      this.$permission.updateUserStorage(res.data)
      this.$permission.loginRole()
      this.$permission.set()
      
      this.$store.dispatch('socket/initWebSocket')
      this.$store.dispatch('setIsShowGoFront')
      this.getStoreSetting()
      
      const path = this.$route.path
      if (path === '/' || path === '/login') {
        if (this.$store.state.storage.manager) {
          this.$router.replace('/store-manage')
        } else if (this.$store.state.storage.uberEatsStoreAuthorization) {
          console.log('授權成功, 準備進行跳轉')
          this.$router.replace('/account-manage/delivery-platform-set')
        } else if (this.showPosSwitch) {
          this.$router.replace('/store-pos')
        } else {
          this.$router.replace('/order-manage/socket')
        }
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setVhHeightStyle)
    if (this.isReactNative) {
      const root = /iPhone|iPad|iPod|Mac/i.test(navigator.userAgent)
          ? window
          : document
      root.removeEventListener('message', this.reactNativeMessage)
    }
  },
  methods: {
    setVhHeightStyle() {
      let height = window.innerHeight
      this.showPosSwitch = window.innerWidth > 975
      if (height < 500) {
        return
      }
      this.height = height
      let vh = height * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    changeFontSize() {
      const num = 12 + this.scaleFont * 4
      document.documentElement.style.fontSize = num + 'px'
    },
    reactNativeMessage(event) {
      const res = JSON.parse(event.data)
      console.info(res)
      switch (res.type) {
        case 'posPrinterStatusChanged':
          
          this.$store.state.order.posPrinterStatus = res.data
  
	        this.$store.state.order.BTPosPrinter = res.data === 'ONLINE'
		        
          break
        case 'posSecondPrinterStatusChanged':
          this.$store.state.order.posSecondPrinterStatus = res.data
	        this.$store.state.order.BTPosPrinterSecond = res.data === 'ONLINE'
          break
        case 'tagPrinterStatusChanged':
          this.$store.state.order.tagPrinterStatus = res.data
	        this.$store.state.order.BTTagPrinter = res.data === 'ONLINE'
          break
        
        case 'posLink':
          this.$store.state.order.BTPosPrinter = res.data
          this.$store.state.order.posPrinterStatus = res.data ? "ONLINE" : "OFFLINE"
          
          break
        
        case 'posSecondLink':
          this.$store.state.order.BTPosPrinterSecond = res.data
          this.$store.state.order.posSecondPrinterStatus = res.data ? "ONLINE" : "OFFLINE"
          break
        case 'tagLink':
          this.$store.state.order.BTTagPrinter = res.data
          this.$store.state.order.tagPrinterStatus = res.data ? "ONLINE" : "OFFLINE"
          break
        
        case 'active':
          if (this.$store.state.storage.userId) {
            if (res.data) {
              this.$store.dispatch('socket/reconnection')
            } else {
              this.$store.dispatch('socket/disconnect')
            }
            this.$store.commit('storage/setActive', res.data)
          }
          break
        case 'goOrder': {
          if (this.storeId) {
            this.$router.replace('/order-manage/socket')
          }
        }
      }
    },
    onGattServerDisconnected(event) {
      this.$store.state.order.BTPosPrinterDevice = null
      this.$store.state.order.BTPosPrinter = null
    },
    onGattServerDisconnectedSecond(event) {
      this.$store.state.order.BTPosPrinterDeviceSecond = null
      this.$store.state.order.BTPosPrinterSecond = null
    },
    getStoreSetting() {
      if (this.storeId) {
        this.$http.store.getStoreSetting(this.storeId).then(res => {
          this.saveAndChangeStoreSetting(res)
        })
        if (this.isReactNative) {
          window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'logonShop',
                data: {
                  status: true,
                  shopId: this.storeId,
                },
              }),
          )
        }
      } else {
        if (this.isReactNative) {
          window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'logonShop',
                data: {
                  status: false,
                },
              }),
          )
        }
      }
    },
    saveAndChangeStoreSetting(res) {
      this.$store.commit(
          'storage/setShopOrderStatus',
          res.data.mobileOrderSwitch,
      )
      this.$store.commit('storage/setHasPos', res.data.printSwitch)
      this.$store.commit('storage/setHasTag', res.data.tagSwitch)
      this.$store.commit('storage/setPosMac', res.data.printMac)
      this.$store.commit('storage/setPosMacSecond', res.data.printMacSecond)
      this.$store.commit('storage/setTagMac', res.data.tagMac)
      this.$store.commit('storage/setPosDeviceId', res.data.printDeviceId)
      this.$store.commit(
          'storage/setPosDeviceIdSecond',
          res.data.printDeviceIdSecond,
      )
      this.$store.commit('storage/setTagDeviceId', res.data.tagDeviceId)
      this.$store.commit(
          'storage/setBackPosCheckoutMode',
          res.data.backPosCheckoutMode,
      )
      this.$store.commit(
          'storage/setBackPosTagMode',
          res.data.backPosTagMode,
      )
      this.$store.commit('storage/setScaleFont', res.data.fontSizeSetting)
      this.$store.commit('storage/setPrintClient', res.data.printClient)
      this.$store.commit(
          'storage/setQuantityKeyboardType',
          res.data.quantityKeyboardType,
      )
      this.$store.commit(
          'storage/setPosLinePaySwitch',
          res.data.payMethod.posLinePaySwitch,
      )
      this.$store.commit(
          'storage/setAdminLinePaySwitch',
          res.data.adminLinePaySwitch,
      )
      this.$store.commit(
          'storage/setIdleOrderReminderSwitch',
          res.data.idleOrderReminderSwitch,
      )
      this.$store.commit(
          'storage/setIdleOrderReminderMinutes',
          res.data.idleOrderReminderMinutes,
      )
      this.changeFontSize()
      this.$store.commit('storage/setInvoiceSwitch', res.data.invoiceSwitch)
      this.$store.commit(
          'storage/setDeliveryPlatformSwitch',
          res.data.deliveryPlatformSwitch,
      )
      
      const updateInvoiceSwitches = {
        admin: res.data.adminInvoiceSwitch,
        store: res.data.storeInvoiceSwitch,
      }
      this.$store.commit('storage/setInvoiceSwitch', updateInvoiceSwitches)
      
      const systemModule = {
        posOrder: (res.data.systemModules.find(module => module.type === 'POS_ORDER') || {}).status || false,
        frontOrder: (res.data.systemModules.find(module => module.type === 'FRONT_ORDER') || {}).status || false,
        mobileOrder: (res.data.systemModules.find(module => module.type === 'MOBILE_ORDER') || {}).status || false,
      }
      this.$store.commit('storage/setSystemModule', systemModule)
      
      if (this.path) {
        this.checkPosSwitch(systemModule.posOrder)
      }
    },
    checkPosSwitch(status) {
      if (!status && !this.manager) {
        this.$router.replace('/order-manage/socket')
      }
    },
    getAnnouncementDisplayList() {
      const isApp = this.isReactNative
      this.$http.announcement
          .getAnnouncementDisplayList({
            storeId: this.storeId,
            app: isApp,
          })
          .then(res => {
            const announcementDisplayList = res.data
            if (isApp) {
              // 檢查APP版號
              this.$http.system
                  .get(
                      this.isMac
                          ? {paramId: 'APP_VERSION_IOS'}
                          : {paramId: 'APP_VERSION_ANDROID'},
                  )
                  .then(response => {
                    const onlineVersion = response.data
                    const currentVersion = this.isMac
                        ? this.$store.state.storage.iosVersion
                        : this.$store.state.storage.androidVersion
                    
                    const newAnnouncementDisplayList = []
                    
                    announcementDisplayList.forEach(announcement => {
                      if (
                          announcement.type === 'APP_UPDATE' &&
                          onlineVersion === currentVersion
                      ) {
                        this.$http.announcement.updateAnnouncementDisplay({
                          announcementId: announcement.id,
                          display: false,
                          storeId: this.storeId,
                        })
                        console.log('版號相等!不添加新APP公告')
                      } else {
                        newAnnouncementDisplayList.push(announcement)
                      }
                    })
                    
                    this.$store.commit(
                        'storage/setAnnouncementDisplayList',
                        newAnnouncementDisplayList,
                    )
                  })
            } else {
              this.$store.commit(
                  'storage/setAnnouncementDisplayList',
                  announcementDisplayList,
              )
            }
          })
    },
		async checkBinding() {
			console.log('檢查whatsEat')
			try {
				// 測試
				const storeId = 654321
				// 正式
				// const storeId = this.storeId
				const res = await this.$http.whatsEatService.checkBinding(storeId)
				if (res.status === 200) {
					this.$store.commit('storage/setWhatsEatBinding', true)
					console.log("店家號碼" + storeId)
					console.log("當前綁定" + this.$store.state.storage.whatsEatBinding)
				}
			} catch (err) {
				this.$store.commit('storage/setWhatsEatBinding', false)
				console.log("店家號碼" + this.storeId)
				console.log("當前綁定" + this.$store.state.storage.whatsEatBinding)
			}
		},
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-snack__content {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.fixed-version {
  position: fixed;
  right: 4px;
  bottom: 4px;
  font-size: 0.75rem;
  user-select: none;
  pointer-events: none;
  z-index: 999;
}

.loading-image {
  height: 200px;
  width: 200px;
}

.load-text {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #000;
  text-align: center;
}

@keyframes move {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1px);
  }
}

@for $i from 1 through 8 {
  .load-text#{$i} {
    margin: 0 2px;
    animation: move 1s infinite ease-in-out;
    animation-delay: ($i - 1) * 0.2s;
  }
}

.toast {
  &::v-deep {
    .v-snack__wrapper {
      min-width: auto;
      max-width: 90vw;
    }
    
    .v-snack__content {
      padding: 14px 40px;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
    }
  }
  
  .error-img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
}

.toast-text {
  text-align: left;
  font-size: 1.275rem;
  white-space: pre-wrap;
}
</style>
