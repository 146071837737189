<template>
	<v-menu
		ref="menu"
		v-model="menu"
		:close-on-content-click="false"
		:return-value.sync="time"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="290px"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="time"
				readonly
				v-bind="attrs"
				v-on="on"
				outlined
			></v-text-field>
		</template>
		<v-time-picker
			v-if="menu"
			v-model="time"
			full-width
			format="24hr"
			color="com1Color"
			@click:minute="$refs.menu.save(time)"
			:min="min"
		></v-time-picker>
	</v-menu>
</template>

<script>
export default {
	name: 'MyTimePicker',
	props: {
		value: {
			type: String | null,
			default: '00:00',
		},
		min: {
			type: String | null,
			default: '00:00',
		},
	},
	data() {
		return {
			time: this.value,
			menu: false,
		}
	},
	watch: {
		value(e) {
			this.time = e
		},
		time(e) {
			this.$emit('input', e)
		},
	},
	methods: {
		onChange(e) {
			this.$emit('input', e)
		},
	},
}
</script>
