<template>
	<v-dialog
		v-model="dialog"
		persistent
		:max-width="742"
		content-class="checkout-dialog"
	>
		<div @click.stop="eventStop">
			<v-card class="checkout-modal">
				<div class="checkout-modal__box" style="padding-right: 16px">
					<div class="modal-title">{{ selectTitle() }}</div>

					<div class="select_box">
						<v-btn
							:class="{ selectedType: selectExpense }"
							class="default_btn select_type"
							@click="setSelectedType('EXPENSE')"
							large
						>
							支出
						</v-btn>
						<v-btn
							:class="{ selectedType: selectIncome }"
							class="default_btn select_type"
							@click="setSelectedType('INCOME')"
							style="text-transform: none"
							large
						>
							收入
						</v-btn>
					</div>
					<MyTextField
						v-model="amount"
						label="金額"
						placeholder="請輸入金額"
            :readonly=true
						:rules="[
							v => (v !== null && typeof v === 'number') || '金額為必填',
						]"
						style="margin-bottom: 8px"
						:labelWidth="70"
					>
					</MyTextField>
					<MyTextField v-model="remark" label="備註" :labelWidth="70">
					</MyTextField>
				</div>
				<div class="checkout-modal__box">
					<div class="right">
						<div class="keyboard">
							<div class="keyboard_box">
								<v-btn
									class="keyboard_item default_btn"
									v-for="e in calc"
									:key="`${e}`"
									@click.stop="onKeydownCalc(e)"
								>
									{{ getCalcText(e) }}
								</v-btn>
							</div>
						</div>
					</div>
					<div class="actions">
						<v-btn
							class="actions_btn default_btn select_btn mr-2"
							@click="cancel"
							large
						>
							取消
						</v-btn>
						<v-btn
							large
							@click="onSubmit"
							:color="submitButtonColor"
							:disabled="submitButtonDisable"
						>
							確認
						</v-btn>
					</div>
				</div>
			</v-card>
			<div class="actions mobile">
				<v-btn class="actions_btn default_btn select_btn" @click="cancel" large>
					取消
				</v-btn>
				<v-btn
					large
					@click="onSubmit"
					:color="submitButtonColor"
					:disabled="submitButtonDisable"
				>
					確認
				</v-btn>
			</div>
		</div>
	</v-dialog>
</template>

<script>
import ForgetPassword from '@/views/ForgetPassword.vue'
import MyTextField from '@/components/TextField.vue'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import SvgIcon from '@jamescoyle/vue-icon'

const CALC_ZERO = 99
const CALC_SUBMIT_NUM = 100
const CALC_DOUBLE_ZERO = 98
const BACKSPACE = 97
const enumCalcText = {
	[CALC_ZERO]: 'C',
	[CALC_SUBMIT_NUM]: '確認',
	[CALC_DOUBLE_ZERO]: '00',
	[BACKSPACE]: 'BACKSPACE',
}

export default {
	name: 'MyCashDrawerRecordModal',
	components: {
		MyTextField,
		ForgetPassword,
		StreamBarcodeReader,
		SvgIcon,
	},
	props: {
		value: {
			required: false,
			type: Boolean,
		},
	},
	computed: {
		isReactNative() {
			return this.$isReactNative()
		},
		// 判斷變色
		submitButtonColor() {
			if (
				this.amount !== null &&
				typeof this.amount === 'number' &&
				this.amount > 0
			) {
				return 'rgb(237, 180, 119)'
			} else {
				return 'spaicel2 bold'
			}
		},
		// 判斷disable
		submitButtonDisable() {
			return (
				this.type === null ||
				this.amount === null ||
				typeof this.amount !== 'number'
			)
		},
	},
	created() {},
	data() {
		return {
			dialog: false,
			calc: [7, 8, 9, 4, 5, 6, 1, 2, 3, 0, CALC_DOUBLE_ZERO, CALC_ZERO],

			id: null,
			type: 'EXPENSE',
			amount: null,
			remark: null,

			selectExpense: true,
			selectIncome: false,
		}
	},
	watch: {},
	mounted() {},
	methods: {
		selectTitle() {
			if (this.id === null) {
				return '新增臨時收支'
			}
			return '編輯臨時收支'
		},
		show(record) {
			this.id = record.id
			this.amount = record.amount
			this.remark = record.remark
			this.setSelectedType(record.type)
			this.dialog = true
		},
		setSelectedType(type) {
			this.type = type
			if (type === 'EXPENSE') {
				this.selectExpense = true
				this.selectIncome = false
			} else if (type === 'INCOME') {
				this.selectExpense = false
				this.selectIncome = true
			}
		},
		cancel() {
			this.dialog = false
		},
		onSubmit() {
			this.dialog = false
			this.$emit('onSubmit', {
				id: this.id,
				type: this.type,
				amount: this.amount,
				remark: this.remark,
			})
		},
		getCalcText(num) {
			return enumCalcText[num] ?? num
		},
		eventStop(e) {
			e.stopPropagation()
		},
		onKeydownCalc(num) {
			switch (num) {
				case CALC_DOUBLE_ZERO:
					if (this.amount === null) {
						this.amount = 0
						break
					} else {
						this.changeCount('00')
						break
					}
				case CALC_ZERO:
					this.amount = 0
					break
				case BACKSPACE:
					if (this.amount !== null && this.amount.length > 1)
						this.amount = this.amount.slice(0, -1)
					break
				default:
					this.changeCount(num)
					break
			}
		},
		changeCount(num) {
			if (this.amount === null || typeof this.amount !== 'number') {
				this.amount = Number(`${num}`)
			} else {
				this.amount = Number(`${this.amount}${num}`)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.checkout-modal {
	display: flex;
	flex-direction: row;
	height: calc(var(--vh, 1vh) * 36 + 13rem);
	min-height: calc(200px + 12rem);
	max-height: calc(var(--vh, 1vh) * 90);
	border-radius: 16px;
	padding: 32px;
	transition: 0.3s;
	box-shadow: none !important;

	.modal-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.5rem;
		font-weight: bold;
		color: #000;
		margin-bottom: 20px;
	}

	.select_box {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 12px;
		.select_type {
			height: 45px;
			width: calc(50% - 2px);
			transition: 0.3s;
			&.selectedType {
				border-color: rgba(214, 45, 45, 0.7);
				background: rgba(213, 40, 40, 0.1) !important;
				color: #d52828;
			}
		}
	}
}
.checkout-modal__box {
	flex: 1;
	display: flex;
	flex-direction: column;

	& .right {
		background: #f6f6f6;
		border-radius: 8px;
		padding: 12px;
		display: flex;
		flex: 1;
		flex-direction: column;
		.select_btn {
			width: 100%;
		}

		.discount_type {
			width: 100%;
			display: flex;
			border-radius: 24px;
			background: #fff;
			height: 48px;
			margin-bottom: 12px;
			padding: 6px;
			position: relative;
			.type_box {
				flex: 1;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1.125rem;
				font-weight: bold;
				color: #000;
				transition: 0.3s;
				z-index: 2;
				&.active {
					color: #fff;
				}
			}
			.type_box_select {
				border-radius: 20px;
				position: absolute;
				height: 36px;
				width: calc(50% - 6px);
				background: #294b71;
				transition: 0.3s;
				z-index: 1;
				&.right {
					transform: translateX(100%);
				}
			}
		}
		.keyboard {
			display: flex;
			flex: 1;
		}
		.keyboard_box {
			display: flex;
			align-content: flex-start;
			flex-wrap: wrap;
			justify-content: space-between;
			height: 100%;
			overflow: hidden;
			flex: 1;
		}
		.keyboard_item {
			width: calc(100% / 3 - 2px);
			height: calc(100% / 4 - 2px);
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 1px;
			cursor: pointer;
			font-size: calc(16px + 0.25rem);
			font-weight: bold;
			background: #fff;
			transition: 0.3s;
			&.active {
				border-color: rgba(214, 45, 45, 0.7);
				background: rgba(213, 40, 40, 0.1) !important;
				color: #d52828;
			}
			&:active {
				background: #dcdcdc;
			}
		}
	}
	.selectInvoiceType {
		border-color: rgba(214, 45, 45, 0.7);
		background: rgba(213, 40, 40, 0.1) !important;
		color: #d52828;
	}
}
@include rwd(589) {
	.checkout-modal {
		flex-direction: column;
		overflow-y: auto;
		flex: none;
		height: calc(var(--vh, 1vh) * 80 - 88px);
		.checkout-modal__box {
			padding: 0 !important;

			.actions {
				display: none;
			}
			.right {
				height: calc(var(--vh, 1vh) * 60 - 88px);
				flex: none;
			}
		}
	}
}

.actions {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	height: 68px;
	&::v-deep {
		&.v-card__actions {
			padding: 0;
		}
		.v-btn {
			height: 56px;
			flex: 1;
		}
	}
	&.mobile {
		margin-top: 0;
		display: none;
	}
	@include rwd(589) {
		height: 88px;
		padding: 16px 32px;
		.select_btn {
			margin-right: 8px;
		}

		&.mobile {
			display: flex;
		}
	}
}

.invoice-type-btn {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.web-scanner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.loading-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loading-animation {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* 載入動畫，這裡使用CSS Spinner來示範 */
.spinner {
	width: 50px;
	height: 50px;
	border: 5px solid #f3f3f3; /* 灰色邊框 */
	border-top: 5px solid #3498db; /* 蓝色顶部 */
	border-radius: 50%;
	animation: spin 2s linear infinite; /* 旋转动画 */
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading-text {
}
</style>
