<template>
	<div class="file">
		<div class="file-label" v-if="!files.length">
			點擊上傳PDF，檔案大小限制5MB
		</div>
		<v-file-input
			outlined
			dense
			multiple
			small-chips
			show-size
			:clearable="false"
			class="file-input"
			v-model="files"
			accept=".pdf"
			id="fileInput"
		>
			<template v-slot:selection="{ file, index }">
				<v-chip
					small
					close
					@click.stop="watchFile(file.id)"
					@click:close="openDelChipModal(file.name, index)"
					:class="{ 'file-chip': file.id }"
				>
					{{ file.name }}
					<v-avatar right v-if="file.id" class="file-download">
						<a>
							<v-icon size="18">mdi-dock-window</v-icon>
						</a>
					</v-avatar>
				</v-chip>
			</template>
		</v-file-input>

		<!-- 刪除檔案 Modal -->
		<v-dialog v-model="isDelChipModal" max-width="290">
			<v-card>
				<v-card-text class="pt-5">
					請確認是否刪除
					<span class="text-red">{{ this.storeDelFiles.name }}</span>
					<v-icon color="#EF5350" small>mdi-alert-circle-outline</v-icon>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue-grey lighten-5" @click="cancelChip">取消</v-btn>
					<v-btn color="primary" @click="delChip">確認</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyFiles',
	model: {
		prop: 'value',
		event: 'updateFile',
	},
	// components: { COMPONENT_NAME },
	props: {
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isDelChipModal: false,
			isDelFiles: false,
			storeFiles: [],
			files: [],
			storeDelFiles: {},
			isFirstIn: true,
		}
	},
	computed: {
		newFiles() {
			return this.value
		},
	},
	watch: {
		value(val) {
			if (this.isFirstIn) {
				this.files = val
				this.isFirstIn = false
			}
		},
		files(val) {
			if (val.length > 3) {
				this.files = []
				this.$store.commit('showMessage', {
					text: '最多上傳3個檔案',
					color: 'error',
				})
			} else {
				val.forEach((x, i) => {
					if (x.size > 5242880) {
						this.files.splice(i, 1)
						this.$store.commit('showMessage', {
							text: '新增失敗！檔案大小超過5MB',
							color: 'error',
						})
					}
				})

				//檢查檔案格式
				if (!this.checkFiles(val)) {
					return
				}

				if (!this.files.length && !this.isDelFiles) {
					this.storeFiles.forEach(file => {
						this.files.push(file)
					})
				} else if (!this.isDelFiles) {
					const store = {}

					val.forEach(file => {
						store[file.name] = file
					})

					this.storeFiles.forEach(file => {
						if (!store[file.name]) {
							this.files.push(file)
						}
					})

					this.storeFiles = val
				}
				this.$emit('updateFile', this.files)
				this.isDelFiles = false
			}
		},
	},
	// created() {},
	mounted() {
		this.files = this.value
		let uploadBtn = document.getElementById('fileInput')
		uploadBtn.addEventListener('click', event => {
			if (this.value.length >= 3) {
				event.preventDefault()
				this.$store.commit('showMessage', {
					text: '最多上傳3個檔案',
					color: 'error',
				})
			}
		})
	},
	// beforeDestroy() {},
	methods: {
		watchFile(id) {
			if (id) {
				const api = process.env.VUE_APP_BASE_API
				window.open(`${api}/api/back/file/${id}`, '_blank')
			}
		},
		openDelChipModal(name, index) {
			this.storeDelFiles = {
				name,
				index,
			}
			this.isDelChipModal = true
		},
		cancelChip() {
			this.isDelChipModal = false
		},
		delChip() {
			this.isDelFiles = true
			this.files.splice(this.storeDelFiles.index, 1)
			this.isDelChipModal = false
		},
		checkFiles(val) {
			let validExt = '.pdf'
			let fileExt = val.map(item => item.name)
			let result = fileExt.every(item => {
				let file = item.substring(item.lastIndexOf('.'))
				return validExt.indexOf(file) > -1
			})
			if (!result) {
				this.$store.commit('showMessage', {
					text: `新增失敗！檔案類型錯誤`,
					color: 'error',
				})
				this.files = []
				return false
			} else {
				return true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep.file {
	position: relative;
	overflow: hidden;
	.file-label {
		position: absolute;
		left: 10px;
		top: 12px;
		color: #9e9e9e;
		font-size: 0.75rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
		padding-right: 10px;
		max-width: 200px;
		@include rwd(375) {
			max-width: 150px;
		}
	}
	.file-input {
		.v-text-field__details,
		.v-input__prepend-outer {
			display: none;
		}
		.v-text-field__slot {
			padding: 5px;
		}
		.v-input__slot {
			margin-bottom: initial;
		}
		.v-input__control > .v-input__slot {
			padding: 0;
		}
	}

	.v-input.file-input {
		min-width: 200px;
		@include rwd(375) {
			min-width: 150px;
		}
	}

	.v-chip.file-chip {
		&:hover {
			background-color: #2ca4e2;
			color: #fff;
			cursor: pointer;
			.v-icon {
				color: #fff;
			}
		}
		.file-download a {
			text-decoration: none;
		}
	}

	.v-chip.v-size--small {
		height: initial;
	}

	.v-chip {
		white-space: initial;
	}

	.v-chip .v-chip__content {
		@include rwd(375) {
			padding: 3px 0;
		}
	}
}
</style>
