import service from './service'

export default {
	// 郵件記錄分頁
	getPage: params => service.post(`back/mail/getPage`, params),

	// 下載郵件內容
	download: params =>
		service.get(`back/mail/download?id=${params}`, { responseType: 'blob' }),

	// 重發郵件
	resend: params => service.get(`back/mail/resend?id=${params}`),
}
