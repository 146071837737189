<template>
	<MyFormRow
		:label="label"
		:validate="validate"
		:width="labelWidth"
		:labelAlign="labelAlign"
		:labelPosition="labelPosition"
		:color="color"
	>
		<v-textarea
			class="my-textarea"
			:class="{ hide_resize: !resize }"
			:value="value"
			:rules="rules"
			:disabled="disabled"
			:readonly="readonly"
			outlined
			dense
			single-line
			:rows="rows"
			@input="onInput"
		></v-textarea>
	</MyFormRow>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyTextarea',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: String,
		},
		rules: {
			required: false,
			type: Array,
			default: () => [],
		},

		rows: {
			required: false,
			type: Number,
			default: 5,
		},
		disabled: {
			required: false,
			type: Boolean,
		},
		readonly: {
			required: false,
			type: Boolean,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
		resize: {
			required: false,
			type: Boolean,
		},
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	// data() {return {}},
	computed: {
		validate() {
			return this.rules.length > 0
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onInput(val) {
			this.$emit('updateValue', val)
			this.$emit('onInput', val)
		},
	},
}
</script>
<style lang="scss" scoped>
.hide_resize {
	::v-deep {
		textarea {
			resize: none !important;
		}
	}
}
</style>
