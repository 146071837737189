<template>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="calc(176px + 20rem)"
		content-class="modal-dialog"
	>
		<div class="reserve" v-if="dialog">
			<div class="reserve_title">預約</div>

			<div class="reserve_body">
				<div class="reserve_input">
					<div class="reserve__btn">
						<MyTextField label="姓名" labelWidth="4.5rem" v-model="name" />
						<v-btn class="btn ml-1" :class="{ active: selectMr }" @click="addMr"
							>先生
						</v-btn>
						<v-btn
							class="btn ml-1"
							:class="{ active: selectMrs }"
							@click="addMrs"
							>小姐
						</v-btn>
					</div>

					<MyTextField
						label="電話"
						labelWidth="4.5rem"
						v-model="phone"
						:rules="[
							v => (v.length > 0 ? /^[0-9]*$/.test(v) || '電話格式不符' : true),
						]"
						inputmode="numeric"
						enterkeyhint="done"
					/>
					<MyTextField
						v-if="showAddress"
						label="外送地址"
						labelWidth="4.5rem"
						v-model="address"
					/>
					<MyTextField
						v-if="showUniformNumber"
						label="統編"
						labelWidth="4.5rem"
						v-model="uniformNumber"
						:error-messages="uniformNumberErrorMessage"
					/>
				</div>
				<div class="reserve__btn">
					<v-btn
						class="btn"
						:class="{ active: searchData === 'fifteen' }"
						@click="setQuickButton(15 * 60 * 1000)"
						>15分鐘後
					</v-btn>
					<v-btn
						class="btn"
						:class="{ active: searchData === 'thirty' }"
						@click="setQuickButton(30 * 60 * 1000)"
						>半個小時後
					</v-btn>
					<v-btn
						class="btn"
						:class="{ active: searchData === 'hour' }"
						@click="setQuickButton(60 * 60 * 1000)"
						>1個小時後
					</v-btn>
				</div>
				<div class="reserve_scroll">
					<ScrollPicker
						v-if="load"
						:options="dates"
						v-model="date"
						style="margin-right: 0.5rem"
					></ScrollPicker>

					<ScrollPicker
						v-if="load && loadHours"
						:options="hours"
						v-model="hour"
						style="margin-right: 0.5rem"
					></ScrollPicker>

					<ScrollPicker
						v-if="load && loadMinutes"
						:options="minutes"
						v-model="minute"
					></ScrollPicker>
				</div>

				<div v-if="!!this.reserve" style="min-height: 2rem; text-align: center">
					<span style="color: red">{{
						this.changeTakeTime ? '取餐時間更改！' : ''
					}}</span>
				</div>
			</div>

			<div class="calc_btnList">
				<v-btn class="calc_btn cancel mr-2" @click.stop="onCancel">取消</v-btn>
				<v-btn class="calc_btn submit" color="com1Color" @click.stop="onSubmit">確定</v-btn>
			</div>
		</div>
	</v-dialog>
</template>

<script>
import { ScrollPicker, ScrollPickerGroup } from 'vue-scroll-picker'
import MyTextField from '@/components/TextField.vue'
import {checkUniformNumber} from "@/rules/checkUniformNumber";

let quickButtonHourTimer
let quickButtonMinuteTimer

export default {
	name: 'MyReserveModal',
	components: {
		MyTextField,
		ScrollPicker,
		ScrollPickerGroup,
	},
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		reserve: {
			type: Object,
			default: () => {},
		},
		showAddress: {
			type: Boolean,
			default: true,
		},
		showUniformNumber: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			name: '',
			phone: '',
			address: '',
			uniformNumber: '',

			selectMr: false,
			selectMrs: false,

			date: null,
			dates: [],

			hour: null,
			hours: [],

			minute: null,
			minutes: [],

			// 原始取餐時間
			originalTakeTime: null,
			changeTakeTime: false,

			load: false,
			loadHours: false,
			loadMinutes: false,
		}
	},
	computed: {
		uniformNumberErrorMessage() {
			let errorMessage = ''
			if (this.uniformNumber && !checkUniformNumber(this.uniformNumber)) {
				errorMessage = '統編不正確';
			}
			return errorMessage
		},
		searchData() {
			const nowTime = new Date()
			nowTime.setMinutes(nowTime.getMinutes() + 15)

			if (
				this.$formatDate(nowTime, 'yyyy-MM-dd HH:mm') ===
				this.$formatDate(
					new Date(`${this.date} ${this.hour}:${this.minute}`),
					'yyyy-MM-dd HH:mm',
				)
			) {
				return 'fifteen'
			}

			nowTime.setMinutes(nowTime.getMinutes() + 15)
			if (
				this.$formatDate(nowTime, 'yyyy-MM-dd HH:mm') ===
				this.$formatDate(
					new Date(`${this.date} ${this.hour}:${this.minute}`),
					'yyyy-MM-dd HH:mm',
				)
			) {
				return 'thirty'
			}

			nowTime.setMinutes(nowTime.getMinutes() + 30)
			if (
				this.$formatDate(nowTime, 'yyyy-MM-dd HH:mm') ===
				this.$formatDate(
					new Date(`${this.date} ${this.hour}:${this.minute}`),
					'yyyy-MM-dd HH:mm',
				)
			) {
				return 'hour'
			}
			return ''
		},
	},
	watch: {
		dialog() {
			if (this.dialog) {
				this.getDates()
				this.getHours()
				this.getMinutes()

				setTimeout(() => {
					this.load = true
					this.loadHours = true
					this.loadMinutes = true

					const date = this.getNowDate()

					if (!!this.reserve) {
						this.name = this.reserve.name
						this.phone = this.reserve.phone
						this.address = this.reserve.address
						this.uniformNumber = this.reserve.uniformNumber
						const takeTimeString = `${this.reserve.date.value} ${this.reserve.time}:00`
						const takeTime = new Date(Date.parse(takeTimeString))
						this.setOriginalTakeTime(takeTime)
					} else {
						this.date = this.formatDateValue(date)
						this.hour = this.formatNumber(date.getHours())
						this.minute = this.formatNumber(date.getMinutes())
						this.name = ''
						this.phone = ''
						this.address = ''
						this.uniformNumber = ''
					}
				}, 300)
			} else {
				this.originalTakeTime = null
				this.date = null
				this.dates = []
				this.hour = null
				this.hours = []
				this.minute = null
				this.minutes = []
				this.$nextTick(() => {
					this.load = false
				})
			}
		},
		date() {
			this.getHours()
			this.getMinutes()
			this.isChangeTakeTime()
		},
		hour() {
			this.getMinutes()
			this.isChangeTakeTime()
		},
		name() {
			if (this.name.endsWith('先生')) {
				this.selectMr = true
				this.selectMrs = false
			} else if (this.name.endsWith('小姐')) {
				this.selectMrs = true
				this.selectMr = false
			} else {
				this.selectMrs = false
				this.selectMr = false
			}
		},
	},

	methods: {
		getNowDate() {
			const date = new Date()
			date.setMinutes(date.getMinutes() + 5)
			return date
		},
		getDates() {
			const array = []
			const now = this.getNowDate()

			const endDate = this.getNowDate()
			endDate.setMonth(endDate.getMonth() + 1)
			endDate.setDate(0)
			const size = endDate.getDate()
			for (let n = 0; n < size; n++) {
				array.push({
					name: this.formatDateDisplay(now),
					value: this.formatDateValue(now),
				})
				now.setDate(now.getDate() + 1)
			}
			this.dates = array
		},
		getHours() {
			const array = []
			const now = this.getNowDate()
			let startHour
			if (this.date === this.formatDateValue(now)) {
				startHour = now.getHours()
			} else if (this.isSelectFutureTime(now)) {
				startHour = 0
			} else {
				startHour = 24
			}
			for (let i = startHour; i <= 23; i++) {
				array.push(this.formatNumber(i))
			}
			this.hours = array

			if (
				// 已有預約時間 而且早於現在時間
				this.originalTakeTime !== null &&
				this.originalTakeTime.getTime() < now.getTime()
			) {
				const takeTimeDate = this.formatDateValue(this.originalTakeTime)
				// 現在選擇日期 不是 取餐時間日期
				if (this.date !== takeTimeDate) {
					return
				}

				const nowDate = this.formatDateValue(now)
				if (takeTimeDate === nowDate) {
					// 與現在時間不同時 額外新增當前選項
					this.setOriginalTakeTimeHour(this.originalTakeTime)
				} else {
					// 僅能有當前選項
					this.hours = []
					this.setOriginalTakeTimeHour(this.originalTakeTime)
				}
			}
		},
		getMinutes() {
			const array = []
			const now = this.getNowDate()

			let startMinute
			if (
				this.date === this.formatDateValue(now) &&
				this.hour === this.formatNumber(now.getHours())
			) {
				startMinute = now.getMinutes()
			} else if (this.isSelectFutureTime(now)) {
				startMinute = 0
			} else {
				startMinute = 60
			}

			for (let i = startMinute; i <= 59; i++) {
				array.push(this.formatNumber(i))
			}

			if (
				// 已有預約時間 而且早於現在時間
				this.originalTakeTime !== null &&
				this.originalTakeTime.getTime() < now.getTime()
			) {
				const takeTimeDate = this.formatDateValue(this.originalTakeTime)
				const takeTimeHour = this.formatNumber(this.originalTakeTime.getHours())

				// 現在選擇日期 不是取餐時間日期 不是取餐時間小時
				if (this.date !== takeTimeDate || this.hour !== takeTimeHour) {
					this.minutes = array
					return
				}

				const nowDate = this.formatDateValue(now)
				const nowHour = this.formatNumber(now.getHours())
				if (takeTimeDate === nowDate && takeTimeHour === nowHour) {
					// 與現在時間同時 額外新增當前選項
					this.minutes = array
					this.setOriginalTakeTimeMinute(this.originalTakeTime)
				} else {
					// 僅能有當前選項
					this.minutes = []
					this.setOriginalTakeTimeMinute(this.originalTakeTime)
				}
			} else {
				this.minutes = array
			}
		},
		setOriginalTakeTime(takeTime) {
			if (!!takeTime) {
				this.originalTakeTime = takeTime
				this.setOriginalTakeTimeDate(takeTime)
				this.setOriginalTakeTimeHour(takeTime)
				this.setOriginalTakeTimeMinute(takeTime)
			}
		},
		setOriginalTakeTimeDate(takeTime) {
			const dateDisplay = this.formatDateDisplay(takeTime)
			const dateValue = this.formatDateValue(takeTime)

			if (!this.dates.some(item => item.value === dateValue)) {
				this.dates.unshift({
					name: dateDisplay,
					value: dateValue,
				})
			}
			this.date = dateValue
		},
		setOriginalTakeTimeHour(takeTime) {
			const takeHour = this.formatNumber(takeTime.getHours())
			if (!this.hours.some(item => item === takeHour)) {
				this.hours.unshift(takeHour)
			}
			this.hour = this.formatNumber(takeHour)
		},
		setOriginalTakeTimeMinute(takeTime) {
			const takeMinute = this.formatNumber(takeTime.getMinutes())

			if (!this.minutes.some(item => item === takeMinute)) {
				this.minutes.unshift(takeMinute)
			}

			this.minute = this.formatNumber(takeMinute)
		},
		isSelectFutureTime(now) {
			if (this.date || this.hour || this.minute) {
				return true
			}

			const selectTimeString = `${this.date} ${this.hour}:${this.minute}:00`
			const selectTime = new Date(Date.parse(selectTimeString))
			return selectTime.getTime() > now.getTime()
		},
		setQuickButton(timeDiff) {
			const now = new Date()
			const time = new Date(now.getTime() + timeDiff)
			clearTimeout(quickButtonHourTimer)
			clearTimeout(quickButtonMinuteTimer)

			this.date = this.formatDateValue(time)
			quickButtonHourTimer = setTimeout(() => {
				this.hour = this.formatNumber(time.getHours())
			}, 100)
			quickButtonMinuteTimer = setTimeout(() => {
				this.minute = this.formatNumber(time.getMinutes())
			}, 150)
		},
		isChangeTakeTime() {
			if (this.originalTakeTime == null) {
				this.changeTakeTime = false
				return
			}

			this.changeTakeTime =
				this.$formatDate(this.originalTakeTime, 'yyyy-MM-dd HH:mm') !==
				this.$formatDate(
					new Date(`${this.date} ${this.hour}:${this.minute}`),
					'yyyy-MM-dd HH:mm',
				)
		},
		formatDateDisplay(date) {
			return `${this.$formatDate(date, 'MM/dd')} ${
				this.$date.dayNamesZh[date.getDay()]
			}`
		},
		formatDateValue(date) {
			return this.$formatDate(date, 'yyyy-MM-dd')
		},
		formatNumber(num) {
			return num < 10 ? `0${num}` : `${num}`
		},
		addMr() {
			if (this.selectMr) {
				return
			}
			if (this.selectMrs || this.name.endsWith('小姐')) {
				this.name = this.name.replace('小姐', '') + '先生'
			} else if (!this.name.endsWith('先生')) {
				this.name = this.name + '先生'
			}
			this.selectMr = true
			this.selectMrs = false
		},
		addMrs() {
			if (this.selectMrs) {
				return
			}
			if (this.selectMr || this.name.endsWith('先生')) {
				this.name = this.name.replace('先生', '') + '小姐'
			} else if (!this.name.endsWith('小姐')) {
				this.name = this.name + '小姐'
			}
			this.selectMrs = true
			this.selectMr = false
		},
		clearSelect() {
			this.selectMr = false
			this.selectMrs = false
		},
		onSubmit() {
			if (this.uniformNumberErrorMessage) {
				return
			}

			this.$emit('onSubmit', {
				date: this.dates.find(v => v.value === this.date),
				time: this.hour + ':' + this.minute,
				name: this.name,
				phone: this.phone,
				address: this.address,
				uniformNumber: this.uniformNumber,
			});
			this.clearSelect()
		},
		onCancel() {
			this.clearSelect()
			this.$emit('onCancel')
		},
	},
}
</script>

<style scoped lang="scss">
@keyframes opacityAmi {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.reserve {
	background: $darkTextColor;
	padding: 16px 32px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-height: calc(var(--vh, 1vh) * 90);

	@include rwd(997) {
		padding: 8px 16px;
	}
	&_title {
		padding-bottom: 13px;
		margin-bottom: 12px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
		font-weight: bold;
		border-bottom: solid 2px $lineColor;
	}
	&_body {
		flex: 1;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
	}
	&__btn {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 6px;
		margin-top: 6px;
		width: 100%;
	}
	&_scroll {
		display: flex;
		flex: 1;
		align-items: center;
		& > * {
			animation: opacityAmi 0.2s 1 both;
		}
	}
	&_input {
		& > * {
			margin-bottom: 6px;
		}
	}
	.btn {
		background: $disableColor !important;
		color: $lightTextColor !important;
		height: calc(32px + 0.5rem);
		border-radius: 8px;
		margin-right: 8px;
		flex: 1;
		&:last-child {
			margin-right: 0;
		}

		&.active {
			background: $com2Color !important;
			color: $darkTextColor !important;
		}
		&::v-deep {
			.v-btn__content {
				font-weight: normal !important;
				line-height: 1.5rem;
			}
		}
	}
}
.separator {
	margin: 0 10px;
	font-size: 24px;
	display: flex;
	align-items: center;
}
.calc_btnList {
	display: flex;
	margin-top: 8px;
	.calc_btn {
		height: 56px;
		flex: 1;
		&.cancel {
			border: solid 2px $lineColor !important;
			background: $darkTextColor !important;
			color: $lightTextColor !important;
		}
	}
}

::v-deep {
	.vue-scroll-picker {
		.vue-scroll-picker-item {
			height: calc(32px + 1rem);
			font-size: calc(16px + 0.5rem);
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			@include rwd(997) {
				font-size: calc(16px + 0.2rem);
			}
		}
		.vue-scroll-picker-layer {
			.top {
				border-bottom: 2px solid $lineColor;
				height: calc(50% - (16px + 0.5rem));
			}
			.middle {
				top: calc(50% - (16px + 0.5rem));
				bottom: calc(50% - (16px + 0.5rem));
			}
			.bottom {
				border-top: 2px solid $lineColor;
				height: calc(50% - (16px + 0.5rem));
			}
		}
	}
	.inline-form__label--validate::after {
		content: '';
	}
}
</style>
