<template>
	<MyModal
		title="數量調整"
		v-model="dialog"
		@onSubmit="submit"
		submit-name="確定"
		@onCancel="cancel"
		cancel-name="取消"
		:max-width="490"
		persistent
		no-select
	>
		<MyRowText
			label="商品名稱"
			:labelWidth="labelWidth"
			style="margin-bottom: 12px"
		>
			<div style="font-weight: bold; color: #000">{{ itemName }}</div>
		</MyRowText>
		<MyFormRow
			label="可販售數量"
			:width="labelWidth"
			labelAlign="left"
			color="dark"
			labelPosition="left"
			class="available-quantity"
		>
			<v-text-field
				v-model="availableQuantity"
				outlined
				dense
				single-line
				placeholder="請輸入可販售數量"
				persistent-hint
				type="number"
				min="1"
				:clearable="availableForSale"
			/>
		</MyFormRow>

		<MyRowText
			label="販售狀態"
			:labelWidth="labelWidth"
			style="margin-bottom: 12px"
		>
			<v-switch
				v-model="availableForSale"
				hide-details
				inset
				:label="availableForSaleLabel"
				:color="availableForSale ? '#27ce8a' : '#8c8c8c'"
				class="available-switch"
				:class="{ close: !availableForSale }"
				style="margin-top: 0; padding-top: 4px; width: max-content"
			></v-switch>
		</MyRowText>
	</MyModal>
</template>

<script>
import MyTextField from '@/components/TextField.vue'
import MyRowText from '@/components/RowText.vue'
import MyModal from '@/components/Modal.vue'
import MyFormRow from '@/components/FormRow.vue'

export default {
	name: 'MyItemStockModal',
	components: { MyFormRow, MyModal, MyRowText, MyTextField },
	data() {
		return {
			dialog: false,
			labelWidth: 150,
			itemId: null,
			itemName: '',
			availableQuantity: null, //可販售數量
			availableForSale: true, //是否可販售
		}
	},
	props: {},
	computed: {
		storeId() {
			const { storeId } = this.$getStoreId()
			return storeId
		},
		availableForSaleLabel() {
			return this.availableForSale ? '正常供應' : '售完'
		},
	},
	methods: {
		convertQuantityToNumber(value) {
			if (value === null || value === undefined) {
				return null
			}

			const number = Number(value)

			if (isNaN(number) || number === 0) {
				return null
			}

			return number
		},
		async show(itemId) {
			this.itemId = itemId
			if (this.storeId && this.itemId) {
				await this.$http.pos
					.getItemStock({ storeId: this.storeId, itemId: this.itemId })
					.then(res => {
						const { name, availableQuantity, availableForSale } = res.data
						this.itemName = name
						this.availableQuantity = availableQuantity
						this.availableForSale = availableForSale

						this.dialog = true
					})
					.catch(err => {
						this.$store.commit('showMessage', {
							text: err ?? '取得庫存失敗',
							color: 'error',
						})
					})
			}
		},
		cancel() {
			this.dialog = false
			this.itemId = null
			this.itemName = ''
			this.availableQuantity = null
			this.availableForSale = true
		},
		async submit() {
			await this.$http.pos
				.updateItemStock({
					storeId: this.storeId,
					itemId: this.itemId,
					availableQuantity: this.convertQuantityToNumber(
						this.availableQuantity,
					),
					availableForSale: this.availableForSale,
				})
				.then(res => {
					const { message } = res
					this.$store.commit('showMessage', {
						text: message,
					})
					this.cancel()
				})
				.catch(err => {
					this.$store.commit('showMessage', {
						text: err ?? '編輯庫存失敗',
						color: 'error',
					})
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.item-stock {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}

.available-quantity {
	margin-bottom: 12px;
	::v-deep {
		.v-input.v-text-field--outlined input {
			font-size: 1rem !important;
			font-weight: bold !important;
		}
	}
}

.available-switch {
	::v-deep {
		.v-input--switch__thumb {
			color: #fff !important;
			caret-color: #fff !important;
		}

		.v-input--switch__track {
			opacity: 1;
		}

		.v-label {
			color: #000;
			font-weight: bold;
			font-size: 1rem;
		}
	}

	&.close {
		::v-deep {
			.v-label {
				color: red;
			}
		}
	}
}
</style>
