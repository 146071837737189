<template>
	<div
		:class="`inline-form inline-form--${color} ${
			'inline-form--location-' + labelPosition
		}`"
	>
		<div
			class="inline-form__label"
			:class="{ 'inline-form__label--validate': validate }"
			v-if="label.length > 0"
			:style="{
				width: labelWidth,
				minWidth: labelWidth,
				textAlign: labelAlign,
			}"
		>
			{{ label }}
			<span v-if="requireDot" style="color: red">*</span>
		</div>
		<div class="inline-form__input">
			<slot></slot>
		</div>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyFormRow',
	// components: { COMPONENT_NAME },
	props: {
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		validate: {
			required: false,
			type: Boolean,
		},
		width: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left', // || top
		},
		requireDot: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	// data() {return {}},
	computed: {
		labelWidth() {
			const { width } = this
			return typeof width === 'number' ? width + 'px' : width
		},
		inputWidth() {
			const { labelWidth, labelPosition } = this
			const width = labelWidth === 'auto' ? `0px` : labelWidth
			return labelPosition === 'left' ? `calc(100% - ${width} - 10px)` : '100%'
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="scss" scoped>
.inline-form {
	display: flex;
	align-items: flex-start;

	@include rwd(560) {
		flex-direction: column;
		&__input {
			width: 100%;
		}
	}
}
.inline-form__label {
	font-size: 1rem;
	color: $lightTextColor;
	margin-right: 25px;
	padding-top: 8px;
	font-weight: bold;
	margin-bottom: 9px;
}
.inline-form__label--validate {
	&::after {
		content: '*';
		color: $activeColor;
		font-weight: bold;
	}
}
.inline-form__input {
	flex: 1;
}
.inline-form--white {
	.inline-form__label {
		color: #fff;
	}
	.inline-form__input {
		&::v-deep {
			.v-text-field--outlined {
				&:not(.v-input--is-focused):not(.v-input--has-state) {
					& > .v-input__control {
						& > .v-input__slot {
							fieldset {
								color: rgba(255, 255, 255, 0.12);
							}
						}
					}
				}
			}
		}
	}
}
.inline-form--location-top {
	flex-direction: column;
	justify-content: flex-start;
	.inline-form__label {
		padding-top: 0;
	}
	.inline-form__input {
		width: 100%;
		flex: initial;
	}
}
</style>
