import service from './service'

export default {
	// 取得平台參數
	get: params => service.get('back/system/get', { params }),

	// 取得平台參數列表
	list: params => service.get(`back/system/list`),

	// 更新平台參數
	updateSystemParam: params => service.put(`back/system/update`, params),

	// 踢出所有使用者
	kickOutAllUser: params => service.get(`back/system/kickOutAllUser`),

	// 緩存空間列表
	cacheNames: params => service.get(`back/system/cacheNames`),

	// 刪除緩存
	deleteCache: params => service.delete(`back/system/deleteCache`, { params }),

	// 清空API統計紀錄
	clearApiStatistic: params =>
		service.delete(`back/system/clearApiStatistic`, { params }),

	// 發送開始維護消息
	startMaintenance: params =>
		service.post(`back/system/startMaintenance`, params),

	// 發送完成維護消息
	endMaintenance: params => service.post(`back/system/endMaintenance`, params),
}
