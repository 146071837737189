import service from './service'

export default {
	login: params => service.post(`back/login`, params),
	logout: () => service.post(`logout`),
	// 取得帳號資訊
	getAccountInfo: params =>
		service.get(`back/account/getStoreInfo`, { params }),
	// 修改密碼
	updatePassword: params => service.put(`back/account/updatePassword`, params),
	// 修改密碼-切換帳號
	updateBackRolePassword: params =>
		service.put(`back/account/updateStorePrincipalPassword`, params),
	getQRcodeHash: params => service.get(`back/store/getQRHash`, { params }),

	//發送驗證碼
	sendVerifyCode: params =>
		service.post(`back/forgotPassword/sendVerifyCode`, params),

	//驗證驗證碼
	verifyCode: params => service.post(`back/forgotPassword/verifyCode`, params),

	//更改密碼
	changePassword: params =>
		service.post(`back/forgotPassword/changePassword`, params),

	// 取得當前登入者資訊
	getCurrent: () => service.get('back/user/getCurrent'),
}
