const downloadFile = (fileBlob, fileName, fileType = 'xlsx') => {
	if (!fileBlob) {
		return
	}
	let url = window.URL.createObjectURL(new Blob([fileBlob]))
	let link = document.createElement('a')
	link.style.display = 'none'
	link.href = url
	link.setAttribute('download', `${fileName}.${fileType}`)
	document.body.appendChild(link)
	link.click()
	link.remove()
}

export default Vue => {
	Vue.prototype.$downloadFile = downloadFile
}
