/**
 * 檢查營業人統一編號：
 * 1. 統一編號預估空號將於 2024 年用罄，故擴增統一編號。
 * 2. 為相容新舊統一編號，檢查邏輯由可被『10』整除改為可被『5』整除。
 *
 * @param uniformNo 統一編號，型別需為String
 */
export function checkUniformNumber(uniformNo) {
    const pattern = /^\d{8}$/;
    if (!pattern.test(uniformNo)) {
        return false;
    }

    let isLegal = false;
    const weight = "12121241";
    let the7thNumberIs7 = false;
    let sum = 0;
    for (let i = 0; i < 8; i++) {
        const tmp = parseInt(uniformNo.charAt(i)) * parseInt(weight.charAt(i));
        sum += Math.floor(tmp / 10) + (tmp % 10); // 取出十位數和個位數相加
        if (i === 6 && uniformNo.charAt(i) === '7') {
            the7thNumberIs7 = true;
        }
    }

    if (the7thNumberIs7) {
        if (sum % 5 === 0 || (sum + 1) % 5 === 0) {
            isLegal = true;
        }
    } else {
        if (sum % 5 === 0) {
            isLegal = true;
        }
    }

    return isLegal;
}
