<template>
	<div
		class="my-card"
		:style="wrapStyle"
		:class="{ card__flashing: this.flashing }"
	>
		<slot></slot>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyCard',
	// components: { COMPONENT_NAME },
	props: {
		borderRadius: {
			required: false,
			type: String,
			default: '5px',
		},
		padding: {
			required: false,
			type: String | Number,
			default: 16,
		},
		minHeight: {
			required: false,
			type: String | Number,
			default: undefined,
		},
		height: {
			required: false,
			type: String | Number,
			default: undefined,
		},
		flashing: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	// data() {return {}},
	computed: {
		cardPadding() {
			const { padding } = this
			return typeof padding === 'number' ? padding + 'px' : padding
		},
		wrapStyle() {
			const style = {}

			if (this.borderRadius) style.borderRadius = this.borderRadius
			if (this.cardPadding) style.padding = this.cardPadding
			if (this.minHeight) style.minHeight = this.minHeight
			if (this.height) style.height = this.height

			return style
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="scss" scoped>
@keyframes promptRedBorderFlashing {
	50% {
		border-color: transparent;
	}
}

.my-card {
	background: #fff;
	margin: 12px;
	overflow: hidden;
	border: 2px solid rgba(0, 0, 0, 0);
}
.card__flashing {
	box-shadow: 0 2px 5px rgba(213, 40, 40, 0.8),
		0 4px 30px rgba(213, 40, 40, 0.6);
	border: 2px solid red;
	animation: promptRedBorderFlashing 2s linear infinite;
}
</style>
