import service from './service'

export default {
	// get: () => service.get(`contract/get`),
	getAllPlan: () => service.get(`back/contract/getPlanList`),
	add: params =>
		service.post(`back/contract/add`, params, {
			headers: { 'Content-Type': 'multipart/form-data' },
		}),
	update: params =>
		service.put(`back/contract/update`, params, {
			headers: { 'Content-Type': 'multipart/form-data' },
		}),
	getList: params => service.post(`back/contract/getPage`, params),
	del: params => service.delete(`back/contract/remove`, { params }),
	getDetail: params => service.get(`back/contract/getDetail`, { params }),
	getSimpleSchemeList: params =>
		service.get(`back/contract/getSimpleSchemeList`, { params }),

	//合約方案列表
	getSchemeList: data => service.post(`back/scheme/getList`, data),
	//取得有該合約方案的合約紀錄的店家
	getUseSchemeStoreList: params =>
		service.get(`back/scheme/getUseSchemeStoreList`, { params }),
	//新增合約方案
	addScheme: data => service.post(`back/scheme/add`, data),
	//編輯合約方案
	updateScheme: data => service.put(`back/scheme/update`, data),
	//刪除合約方案
	removeScheme: params => service.delete(`back/scheme/remove`, { params }),
	//上傳合約方案
	uploadScheme: params =>
		service.post(`back/scheme/upload`, params, {
			headers: { 'Content-Type': 'multipart/form-data' },
		}),
	download: params =>
		service.get(`back/scheme/download/${params}`, { responseType: 'blob' }),
}
