<template>
	<div class="checkbox" @click="click">
		<img v-if="!value" src="../assets/box.png" alt="" class="checkbox__img" />
		<img
			v-else="!value"
			src="../assets/checkBox.png"
			alt=""
			class="checkbox__img"
		/>
		{{ label }}
	</div>
</template>

<script>
export default {
	name: 'MyNewCheckBox',
	props: {
		value: {
			required: false,
			type: Boolean,
			default: false,
		},
		label: {
			required: false,
			type: String,
			default: '',
		},
	},

	computed: {},
	methods: {
		click() {
			this.$emit('input')
		},
	},
}
</script>

<style scoped lang="scss">
.checkbox {
	display: flex;
	align-items: center;
	font-size: 1rem;
	color: #000;
	&__img {
		margin-right: 5px;
		height: 24px;
		width: 24px;
	}
}
</style>
