const tableFooter = {
	createTableFooterProps: () => ({
		'items-per-page-options': [10, 20, 30, 40, 50],
		'items-per-page-text': '每頁行數',
	}),
}

export default Vue => {
	Vue.prototype.$tableFooter = tableFooter
}
