/**
 * 數字加逗號，step(參數2)為多少位加逗號，預設千非位
 * @type {function(price: string | number, step: number): string}
 */
export const stepPrice = (() => {
	const ds = {}
	const createStepD = step =>
		(ds[step] = Array.from(new Array(step), (_, i) => i).reduce(
			p => p + '\\d',
			'',
		))
	return (price, step = 3) => {
		let _price = price

		if (typeof price === 'number') _price = String(price)

		if (typeof _price === 'string') {
			const stepD = ds[step] || createStepD(step)
			_price = _price.replace(
				new RegExp(`(\\d)(?=(${stepD})+(?!\\d))`, 'g'),
				'$1,',
			)
		}
		return _price
	}
})()

export const isInt = value => {
	return parseInt(value) === parseFloat(value)
}

/**
 * 格式化給定的數字到固定的小數位精度。
 * @param {number|string} num - 要格式化的數字或可以轉換成數字的字符串。
 * @returns {number|null} - 格式化後具有固定小數位精度的數字。若輸入無效則返回null。
 */
export function formatDecimal(num) {
	if (num === null || num === undefined) {
		return null
	}

	let number = parseFloat(num)

	// 檢查轉換後的數字是否為NaN
	if (isNaN(number)) {
		return null
	}

	return parseFloat(number.toFixed(2))
}
