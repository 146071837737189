import axios from 'axios'

const whatsEatService = axios.create({
	baseURL: `https://api-v3.whatseat.store`,
	timeout: 60000,
})



export default whatsEatService
