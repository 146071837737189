<template>
	<div @click="click">
		<div class="switch" v-if="!simple">
			<img
				class="switch-icon"
				v-if="!value"
				src="../assets/Switch.png"
				alt=""
			/>
			<img class="switch-icon" v-else src="../assets/SwitchOpen.png" alt="" />
		</div>
		<div class="switch-simple" v-else>
			<img
				class="switch-icon"
				v-if="!value"
				src="../assets/Switch-simple.png"
				alt=""
			/>
			<img
				class="switch-icon"
				v-else
				src="../assets/SwitchOpen-simple.png"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MyNewSwitch',
	props: {
		value: {
			required: false,
			type: Boolean,
			default: false,
		},
		simple: {
			required: false,
			type: Boolean,
			default: false,
		},
	},

	computed: {},

	methods: {
		click() {
			this.$emit('input')
		},
	},
}
</script>

<style scoped lang="scss">
.switch {
	width: 82px;
	height: 36px;
	position: relative;
}
.switch-simple {
	width: 48px;
	height: 28px;
}
.switch-icon {
	height: 100%;
	width: 100%;
}
</style>
