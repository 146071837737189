<template>
	<div class="delete-text">
		<img class="delete-text__icon" src="../assets/common_img_warm.png" alt="" />
		<div class="delete-text__title">{{ title }}</div>
		<div class="delete-text__txt"><slot></slot></div>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyDeleteText',
	// components: { COMPONENT_NAME },
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	// data() {return {}},
	// computed: {},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="scss" scoped>
.delete-text {
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 23px;

	&__icon {
		width: 80px;
		height: 80px;
		margin-bottom: 16px;
	}
	&__title {
		font-size: 1.5rem;
		font-weight: bold;
		color: $lightTextColor;
		text-align: center;
		line-height: 2rem;
		margin-bottom: 8px;
	}
	&__txt {
		font-size: 1.125rem;
		color: $lightTextColor;
		text-align: center;
	}
}
</style>
